import { makePersisted } from "@solid-primitives/storage";
import ArrowDown01Icon from "lucide-solid/icons/arrow-down-0-1";
import ArrowDown10Icon from "lucide-solid/icons/arrow-down-1-0";
import ChevronDownIcon from "lucide-solid/icons/chevron-down";
import ChevronRightIcon from "lucide-solid/icons/chevron-right";
import PhoneIcon from "lucide-solid/icons/phone";
import { Component, createSignal, For, Show } from "solid-js";
import { Dynamic } from "solid-js/web";
import { useDriverlocations } from "./DriverlocationsProvider";
import { ct } from "./i18n";
import { IdentifierBadge } from "./vehicles";

type Props = {
  onDriverClick?: (driver_id: string) => void;
};

export const Driverlist: Component<Props> = (props) => {
  const { driverlocationsArray } = useDriverlocations();
  const [collapsedZipcodeIds, setCollapsedZipcodeIds] = makePersisted(createSignal<number[]>([]), {
    name: "mooovex:driverlistCollapsedZipcodeIds",
  });
  const [zipcodeOrder, setZipcodeOrder] = makePersisted(createSignal<"asc" | "desc">("asc"), {
    name: "mooovex:driverlistZipcodeOrder",
  });

  const zipcodes = () => {
    const zipcodesSet = new Set<number>();
    const zipcodesArray: Array<{ zipcodeId: number; zipcode: string; municipiality: string }> = [];

    for (const driverlocation of driverlocationsArray()) {
      if (!zipcodesSet.has(driverlocation.vehicleZipcodeId)) {
        zipcodesSet.add(driverlocation.vehicleZipcodeId);
        zipcodesArray.push({
          zipcodeId: driverlocation.vehicleZipcodeId,
          zipcode: driverlocation.vehicleZipcode,
          municipiality: driverlocation.vehicleMunicipiality,
        });
      }
    }

    zipcodesArray.sort((a, b) => parseInt(a.zipcode) - parseInt(b.zipcode));
    if (zipcodeOrder() === "desc") {
      zipcodesArray.reverse();
    }

    return zipcodesArray;
  };

  return (
    <>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-border-dark d-flex justify-content-end align-items-center gap-2"
          onclick={() => setZipcodeOrder((prev) => (prev === "asc" ? "desc" : "asc"))}
        >
          {ct[zipcodeOrder() === "asc" ? "order.ascending" : "order.descending"]()}
          <Dynamic size={20} component={zipcodeOrder() === "asc" ? ArrowDown01Icon : ArrowDown10Icon} />
        </button>
      </div>
      <table class="table table-sm">
        <For each={zipcodes()} fallback={<div class="text-center">{ct.driverlist.noDrivers()}</div>}>
          {(zipcode) => {
            const zipcodeDrivers = () =>
              driverlocationsArray()
                .filter((driverlocation) => driverlocation.vehicleZipcodeId === zipcode.zipcodeId)
                .sort((a, b) => parseInt(a.vehicleIdentifier) - parseInt(b.vehicleIdentifier));

            const isZipcodeCollapsed = () => collapsedZipcodeIds().includes(zipcode.zipcodeId);

            function toggleZipcodeCollapsed() {
              if (isZipcodeCollapsed()) {
                setCollapsedZipcodeIds((collapsedZipcodeIds) =>
                  collapsedZipcodeIds.filter((id) => id !== zipcode.zipcodeId)
                );
              } else {
                setCollapsedZipcodeIds((collapsedZipcodeIds) => [...collapsedZipcodeIds, zipcode.zipcodeId]);
              }
            }

            return (
              <>
                <thead>
                  <tr onclick={toggleZipcodeCollapsed} class="cursor-pointer user-select-none">
                    <th style={{ width: "24px" }}>
                      <Dynamic size={24} component={isZipcodeCollapsed() ? ChevronRightIcon : ChevronDownIcon} />
                    </th>
                    <th colspan={2} class="text-truncate" style={{ "font-weight": "bold" }}>
                      {zipcode?.municipiality ?? ct["driverlist.noZipcode"]()}
                    </th>
                    <th style={{ "font-weight": "normal", "text-align": "end" }}>({zipcodeDrivers().length})</th>
                  </tr>
                </thead>
                <Show when={!isZipcodeCollapsed()}>
                  <tbody>
                    <For each={zipcodeDrivers()}>
                      {(driver) => {
                        return (
                          <>
                            <tr class="cursor-pointer align-middle">
                              <td onclick={() => props.onDriverClick?.(driver.driverId)}>
                                <IdentifierBadge value={driver.vehicleIdentifier} status={driver.status} />
                              </td>
                              <td
                                class="fw-bold text-nowrap w-100 text-truncate"
                                onclick={() => props.onDriverClick?.(driver.driverId)}
                              >
                                {driver.driverName}
                              </td>
                              <td class="text-end" onclick={() => props.onDriverClick?.(driver.driverId)}>
                                {driver.vehicleSeats}
                              </td>
                              <td style={{ width: "24px" }}>
                                <button
                                  class="btn btn-light text-primary p-0 m-0 lh-1"
                                  style={{ width: "24px" }}
                                  title={`${driver.phoneNumber} (${ct["common.rightClickToCopy"]()})`}
                                  onclick={() => window.open(`tel:${driver.phoneNumber}`)}
                                >
                                  <PhoneIcon size={24} />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      }}
                    </For>
                  </tbody>
                </Show>
              </>
            );
          }}
        </For>
      </table>
    </>
  );
};
